import './AlertWindow.css';


export default function MyWindow(pr)
	{
	let window_type_class = "al";
	if(pr.alert_window === "false")
		{
		window_type_class = "normal";
		}
	
	function show_base()
		{
		if(pr.base === "true")
			return (
					<div className={window_type_class+"_window_base"}>
					</div>
					);
		else
			return "";
		}

	function show_title()
		{
		if(pr.title !== "")
			return (
					<div className={window_type_class+"_window_title"}>
						<span>{pr.title}</span>
					</div>
					);
		else
			return "";
		}
	
	return (
				<div>
					{show_base()}
					<div className={window_type_class+"_window_container"}>
						{show_title()}
						<div className={window_type_class+"_window_msg"}>
							<span>{pr.msg}</span>
						</div>
						<div className={window_type_class+"_window_ctrl"}>
							<button onClick={pr.btn_onclick} type="button" className="al_window_ctrl_btn">{pr.btn_txt}</button>
						</div>
					</div>
				</div>
			);
	}