import './OffersMini.css';
import { HashLink as Link } from 'react-router-hash-link';

export default function OffersMini(pr)
	{
	var offers_arr = [];
	let offer_arr = document.getElementsByClassName("offer_main_mini");
	try
		{
		offers_arr = JSON.parse(pr.offers_arr);	
		}
	catch (e)
		{	
		}
	return (
			<div id="offers_section_mini">
				<span id="oferte"><h1>Oferte Speciale</h1></span>
				<div id="offers_container_mini">
					<div id="offers_container_mini_int">
						{offers_arr.map((item, id) => (
													<Link to={"/Offers/"+pr.lang+"?offer="+item["id"]} key={id}>
													<div id={"offer_"+id} className="offer_main_mini">
														<div className="offer_title_mini">
															<span>{item["name"]}</span>
														</div>
														<div className="offer_image_mini">
															<img className="offer_img_mini" src={pr.img_loc+item["img"]} alt={"offer image "+item["img"]}/>
														</div>
													</div>
													</Link>
													)
										)}
					</div>
				</div>
			</div>
			);
	}