import './Prices.css';

export default function Prices(pr)
	{
	function show_prices()
		{
		return (
				Object.keys(pr.prices[0]).map((id) => (
														<tr key={id} className="price_tr">
															<td className="price_per_td">
																<span>{pr.prices[0][id]}</span>
															</td>
															<td className="price_pr_td">
																<span>{pr.prices[1][id]}</span>
															</td>
														</tr>
														))
				);
		}

	return (
			<div>
				<span><h1>{pr.title}</h1></span>
				<br />
				<table id="prices_tb">
					<thead>
					<tr>
						{pr.head.split(";").map((item, id) => (
															<th key={id} className="price_th">
																<span>{item}</span>
															</th>
															)
												)}
					</tr>
					</thead>
					<tbody>
						{show_prices()}
					</tbody>
				</table>
				<br />
				<span>{pr.desc}</span>
				<br />
			</div>
			);
	}