import './Menu.css';
import menu_btn_img from '../images/menu_btn.png';
import { Outlet} from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { useState, useEffect} from "react";
import XmlRequest from '../comp/XmlReq.js';

export default function Menu(pr)
	{
	var server = "https://nomadhomeoffice.eu/";
	var [menuOpt, setMenuOpt] = useState({menu_items:[],to_remove:[]});
	var pr_addr = pr.opt_addr.split(";");
	var opt_arr = [];
	
	useEffect(() => {
					XmlRequest("POST", server+"get_menu.php", "language="+pr.active_lang).then
						(
						function(value) {
										setMenuOpt(JSON.parse(value));
										},
						function(error) {console.log(error);}
						);
					}, [pr.active_lang, server]);

	function check_menu()
		{
		if(menuOpt.to_remove.length > 0)
			{
			var found = false;
			for(var i=0;i<pr_addr.length;i++)
				{
				found = false;
				for(var j=0;j<menuOpt.to_remove.length;j++)
					{
					if(pr_addr[i].search(menuOpt.to_remove[j]) >= 0)
						{
						found = true;
						break;
						}
					}
				if(!found)
					{
					opt_arr[i] = {name: menuOpt.menu_items[i], addr: pr_addr[i]};
					}
				}
			}
		else
			{
			for(i=0;i<menuOpt.menu_items.length;i++)
				{
				opt_arr[i] = {name: menuOpt.menu_items[i], addr: pr_addr[i]};
				}
			}
		}

	function nav_open()
		{
		var nav = document.getElementById("side_menu");
		nav.style.width = "300px";
		}
	function nav_close()
		{
		var nav = document.getElementById("side_menu");
		nav.style.width = "0px";
		}	
	function side_lang()
		{
		document.getElementById("side_lang_container").classList.toggle("show_side_lang");
		}
		
	check_menu();

	return (
			<div id="menu_container">
				<img id="menu_btn" className="img_container_dim" src={menu_btn_img} onClick={()=>nav_open()} alt="Menu" />
				<ul id="ul_menu">
					{opt_arr.map((item, id) => (
												<Link key={id} to={item.addr}>
													<li className={item.addr === pr.ad_cl_to ? "li_menu li_contact" : "li_menu"}>
														{item.name}
													</li>
												</Link>
												)
								)}
				</ul>
				<div id="side_menu">
					<div id="div_flags" onClick={()=>{side_lang();}}>
						<img className="img_container_dim" src={server+"images/side-lang.png"} alt="" />
						<div id="side_lang_container">
						{pr.languages.map((item, id) => (
														<Link key={id} to={item==="ro" ? "/" : "/"+item}>
															<img id={"lang_img_"+item} className="img_container_dim" src={server+"images/"+item+".png"} alt={"language "+item} />
														</Link>
													)
										)
						}
						</div>
					</div>
            		<ul id="menu_ul_side">
						<li id="close_side_menu" onClick={()=>nav_close()}>&times;</li>
						{opt_arr.map((item, id) => (
												<li key={id} onClick={()=>nav_close()} className={item.name === pr.ad_cl_to ? "menu_link_side menu_link_contact_side" : "menu_link_side"}>
													<Link to={item.addr}>
														{item.name}
													</Link>
												</li>
												)
								)}
					</ul>
				</div>
			<Outlet />
			</div>
			);
	}