import './VisitForm.css';
import FormEdit, {EditM, EditChAcordF, Option} from './FormEdit.js';
import { Link, Outlet } from "react-router-dom";
import { useState} from "react";
import MyWindow from '../comp/AlertWindow.js';

export default function FormVisit(pr)
	{
	// am scos zona pentru data din formular deocamdata
	//<Option optionData={pr.option_data} id="visit_opt" name="visit_date" />
	var pr_nume_campuri = pr.nume_campuri.split(";");
	var [alert_window, setAlertWindow] = useState("");
	
	function send_email()
		{
		if(document.getElementById("f_input_visit_acord").checked)
			{
			var formData = new FormData(document.getElementById('visit_form'));
			var xhr = new XMLHttpRequest();
			xhr.open("POST", 'https://nomadhomeoffice.eu/save_visits.php', true);
			xhr.send(formData);
			xhr.onreadystatechange=function()
				{
				if (xhr.readyState===4)
					{
					if (xhr.status===200 || window.location.href.indexOf("http")===-1)
						{
						switch(xhr.responseText)
							{
							case "sent":{
										setAlertWindow(pr.msg_ok);
										clear_form();
									}
							break;
							case "1": setAlertWindow(pr.msg_no_name);
							break;
							case "2": setAlertWindow(pr.msg_no_email);
							break;
							case "3": setAlertWindow(pr.msg_no_data);
							break;
							default: console.log(xhr.responseText);
							}
						}
					else
						{
						setAlertWindow("Error in request.");
						}
					}
				};
			}
		else
			{
			setAlertWindow(pr.notice);
			}
		}

	function clear_form()
		{
		document.getElementById('f_input_visit_name').value = "";
		document.getElementById('f_input_visit_email').value = "";
		document.getElementById('f_input_visit_acord').checked = false;
		}

	function MyAlert()
		{
		if(alert_window !== "")
			{
			return (
				<div id="al_window">
					<MyWindow base="true" 
							  alert_window="true"
							  title=""
							  msg={alert_window}
							  btn_onclick={()=>{setAlertWindow("");}}
							  btn_txt="OK"
							  />
				</div>
				);
			}
		else
			{
			return "";
			}
		}

	return (
		<div id="form_visit_container">
		{MyAlert()}
			<form id="visit_form">
				<FormEdit exit_text={pr_nume_campuri[1]} id="visit_name" type="text" input_name="name" />
				<FormEdit exit_text={pr_nume_campuri[2]} id="visit_email" type="text" input_name="email" />
				<EditChAcordF exit_text={pr.mesaj_gdpr} id="visit_acord" input_name="form_acord" value="form_acord" />
				<EditChAcordF exit_text={pr.mesaj_anunturi} id="visit_anunturi" input_name="form_anunturi" value="1" />
				<button id="visit_submit" type="button" className="res_btn f_section" onClick={()=>{send_email();}}>Submit</button>
			</form>
		<Outlet />
		</div>
	);
	}