import './Form.css';
import FormEdit, {EditM, EditChAcordF} from './FormEdit.js';
import { Link, Outlet } from "react-router-dom";
import { useState} from "react";
import MyWindow from '../comp/AlertWindow.js';

export default function Formxc(pr)
{
	//transaction_id is for google tag manager
	var transaction_id = 10125;
	var [alert_window, setAlertWindow] = useState("");
	function send_email()
		{
		if(document.getElementById("f_input_acord").checked)
			{
			var formData = new FormData(document.getElementById('contact_form'));
			var xhr = new XMLHttpRequest();
			xhr.open("POST", 'https://nomadhomeoffice.eu/send_form.php', true);
			//xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			xhr.send(formData);
			xhr.onreadystatechange=function()
				{
				if (xhr.readyState===4)
					{
					if (xhr.status===200 || window.location.href.indexOf("http")===-1)
						{
						switch(xhr.responseText)
							{
							case "sent":{
										if(window.dataLayer !== undefined)
											{
											window.dataLayer.push({
															event: 'Custom_Form_Submit',
																eventProps: {
																category: 'contact',
																action: 'none',
																label: 'Contact_Form',
																value: 'value'
																}
															});
											}
										clear_form();
										setAlertWindow(pr.res_msg_sent);
										}
								break;
							case "1": setAlertWindow(pr.res_msg_err);
								break;
							case "2": setAlertWindow(pr.res_msg_err);
								break;
							case "4": setAlertWindow(pr.res_msg_no_name);
								break;
							case "5": setAlertWindow(pr.res_msg_no_period);
								break;
							case "6": setAlertWindow(pr.res_msg_no_phone);
								break;
							case "7": setAlertWindow(pr.res_msg_no_email);
								break;
							default: console.log(xhr.responseText);
							}
						}
					else
						{
						setAlertWindow("Error in request.");
						}
					}
				};
			}
		else
			{
			setAlertWindow(pr.notice);
			}
		}
	
	function clear_form()
		{
		document.getElementById('f_input_perioada').value = "";
		document.getElementById('f_input_name').value = "";
		document.getElementById('f_input_email').value = "";
		document.getElementById('f_input_phone').value = "";
		document.getElementById('f_input_acord').checked = false;
		document.getElementById('f_text_message').value = " ";
		}
		
	var pr_nume_campuri = pr.nume_campuri.split(";");

	function MyAlert()
		{
		if(alert_window !== "")
			{
			return (
				<div id="al_window">
					<MyWindow base="true" 
							  alert_window="true"
							  title=""
							  msg={alert_window}
							  btn_onclick={()=>{setAlertWindow("");}}
							  btn_txt="OK"
							  />
				</div>
				);
			}
		else
			{
			return "";
			}
		}

	return (
		<div id="form_container" className="f_cont">
		{MyAlert()}
			<form id="contact_form">
				<FormEdit exit_text={pr_nume_campuri[0]} read_only="true" cl_in="tcal" id="perioada" type="text" input_name="perioada" />
				<FormEdit exit_text={pr_nume_campuri[1]} id="name" type="text" input_name="name" />
				<FormEdit exit_text={pr_nume_campuri[2]} id="email" type="text" input_name="email" />
				<FormEdit exit_text={pr_nume_campuri[3]} id="phone" type="text" input_name="phone" />
				<EditM exit_text={pr_nume_campuri[4]} id="message" input_name="message" />
				<EditChAcordF exit_text={pr.mesaj_gdpr} id="acord" input_name="form_acord" value="form_acord" />
				<button id="submit" type="button" className="res_btn" onClick={()=>{send_email();}}>Submit</button>
			</form>
		<Outlet />
		</div>
	);
}