import './Home.css';
import logo_main from '../images/logo.jpg';
import Menu from '../comp/Menu.js';
import PhotoG from '../comp/PhotoG2.js';
import Prices from '../comp/Prices.js';
import OffersMini from '../comp/OffersMini.js';
import FormRez from '../comp/Form.js';
import FormVisit from '../comp/VisitForm.js';
import MyWindow from '../comp/AlertWindow.js';
import { useState, useEffect} from "react";
import XmlRequest from '../comp/XmlReq.js';
import { Outlet, useParams } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

export default function Home()
	{
	var server = "https://nomadhomeoffice.eu/";
	
	//dbdata stores data from server
	var [vision_form, setVisionForm] = useState(false);
	var [dbdata, setData] = useState({lang:[], 
									poze_slide:[], 
									title_sectiune:"", 
									text_home:"",
									text_home_info:"",
									titlu_desc:"", 
									desc:"", 
									price_title:"",
									price_desc:"",
									price_tb_head:"",
									prices_arr:[],
									offers_arr:[],
									v_periods_arr:[],
									gallery:[], 
									contact_title:"", 
									desc_contact:"", 
									menu:"",
									form_names:"",
									form_condition:"",
									form_notice:"",
									form_visit_title:"",
									form_visit_txt:"",
									open_visit_btn:"",
									visit_msg_ok:"",
									visit_msg_no_name:"",
									visit_msg_no_email:"",
									visit_msg_no_data:"",
									res_form_msg_sent:"",
									res_form_msg_err:"",
									res_form_msg_no_name:"",
									res_form_msg_no_period:"",
									res_form_msg_no_phone:"",
									res_form_msg_no_email:"",
									cal_txt:"",
									cal_btn:"",
									semnatura:"",
									link_gdpr:"",
									title:"",
									meta_desc:"",
									meta_key:""
									});
	var slide_img_loc = "";
	var slide_timer = null;
	var lang = "";
	function set_lang(param_lang)
		{
		if(param_lang !== undefined)
			{
			lang = param_lang;
			}
		else
			{
			lang = "ro";
			}
		}
	set_lang(useParams().lang);
	if(window.innerWidth > 768)
		{
		slide_img_loc = server+"pictures/";
		}
	else
		{
		slide_img_loc = server+"pictures/small/";
		}

	// get data from server
	useEffect(() => {
					XmlRequest("POST", server+"get_info2.php", "language="+lang).then
						(
						function(value) {
										if(value !== "")
											{
											document.getElementById("div_spinner").style.display = "none";
											}
										setData(JSON.parse(value));
										set_height();
										reset_slide();
										if(lang === "")
											{
											document.documentElement.lang ="ro";
											}
										else
											{
											document.documentElement.lang = lang;
											}
										},
						function(error) {console.log(error);}
						);
					return () => {
								clearInterval(slide_timer);
								}
					}, [lang]);
	
	function set_meta()
		{
		document.title = dbdata.title;
		
		if(document.querySelector('meta[name="description"]') !== null)
			{
			document.querySelector('meta[name="description"]').setAttribute("content", dbdata.meta_desc);
			}
		if(document.querySelector('meta[name="keywords"]') !== null)
			{
			document.querySelector('meta[name="keywords"]').setAttribute("content", dbdata.meta_key);
			}
		}
	
	function set_height()
		{
		if(window.innerWidth > 768)
			{
			document.getElementById("gal_view_photo").style.height = document.getElementById("middle").offsetHeight + "px";
			}
		}
	
	function reset_slide()
		{
		clearInterval(slide_timer);
		slide_timer = setInterval(slide_sh, 8000); // Change image every 8 seconds
		}
	
	function slide_sh()
		{
			var img = document.getElementsByClassName("img_pres");
			if(img != null && img.length > 1)
				{
				for(var i = 0;i < img.length;i++)
					{
					if(img[i].classList.contains("img_pres_active"))
						{
						img[i].classList.toggle("img_pres_active");
						if(i+1 < img.length)
							{
							img[i+1].classList.toggle("img_pres_active");
							}
						else
							{
							img[0].classList.toggle("img_pres_active");
							}
						break;
						}
					}
				}
		}
	function gotoForm()
		{
		document.getElementById('form_input').scrollIntoView(true);
		document.getElementById('f_input_perioada').value = document.getElementById('calendar').value;
		document.getElementById('f_input_name').focus();
		}
		
	function show_offers()
		{
		if(dbdata.offers_arr !== "[]")
			{
			return (
					<div id="offers" className="rows-h">
						<div id="offers_int" className="row_center_div">
							<OffersMini offers_arr={dbdata.offers_arr} img_loc={slide_img_loc} lang={lang}/>
						</div>
					</div>
					);
			}
		else
			{
			return "";
			}
		}

	function getCookie(key)
		{
        const cookies = document.cookie
          .split(";")
          .reduce(
            (ac, cv, i) =>
              Object.assign(ac, { [cv.split("=")[0].trim()]: cv.split("=")[1] }),
            {}
          )["cookieyes-consent"];
		if(cookies !== null)
			{
			const { [key]: value } = cookies
				.split(",")
				.reduce(
						(obj, pair) => (
										(pair = pair.split(":")), (obj[pair[0]] = pair[1]), obj
										),
						{}
						);
				return value;
			}
		}

	function show_map()
		{
		/*
		if (getCookie("consent") === "yes" && getCookie("analytics") === "yes")
			{
			return (
					<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d854.6931555160893!2d25.63113822467913!3d45.61416126187303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sro!2sro!4v1690458485554!5m2!1sro!2sro"
						width="100%"
						height="450"
						title="Locatie predare"
						style={{border:0}}
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade">
					</iframe>
					);
			}
		else
			{
			return (
					<a id="a_harta_img" href="https://www.google.com/maps/search/?api=1&query=45.614161%2C25.631138">
						<img id="harta_img" className="img_container_dim" src={server+"images/harta.jpg"} alt="harta" />
					</a>
					);
			}
		*/
		}

	function open_vision()
		{
		if(vision_form)
			{
			return (
				<div id="div_vision_form">
					<MyWindow base="true" 
							  alert_window="false"
							  title={dbdata.form_visit_title}
							  msg={
								<div>
									<span>{dbdata.form_visit_txt}</span>
									<br />
									<FormVisit nume_campuri={dbdata.form_names} 
										option_data={dbdata.v_periods_arr} 
										notice={dbdata.form_notice} 
										mesaj_anunturi={dbdata.form_anunturi} 
										mesaj_gdpr={dbdata.form_condition} 
										link_gdpr={dbdata.link_gdpr} 
										msg_ok={dbdata.visit_msg_ok}
										msg_no_name={dbdata.visit_msg_no_name}
										msg_no_email={dbdata.visit_msg_no_email}
										msg_no_data={dbdata.visit_msg_no_data}
										/>
								</div>
								}
							  btn_onclick={()=>{setVisionForm(false);}}
							  btn_txt="Cancel"
							  />
				</div>
				);
			}
		else
			{
			return "";
			}
		}
		
	function show_prices()
		{
		if(typeof dbdata.prices_arr[0] !== 'undefined')
			{
			if(dbdata.prices_arr[0].length > 0)
				{
				return (
					<div id="tarife" className="rows-h">
						<div id="tarife_int" className="row_center_div">
							<Prices title={dbdata.price_title} desc={dbdata.price_desc} head={dbdata.price_tb_head} prices={dbdata.prices_arr}/>
						</div>
					</div>
					);
				}
			else
				{
				return "";
				}
			}
		else
			{
			return "";
			}
		
		}

  return (
    <div id="div_main">
		{open_vision()}
		<div id="div_spinner">
			<img id="img_spinner" src={server+"images/gear_loader.png"} alt="" />
		</div>
		<div id="top">
			<div id="logo_container">
				<a id="logo_a" href={server}>
					<img id="logo_img" className="img_container_dim" src={logo_main} alt="logo" />
				</a>
			</div>
			<div id="top_menu">
				<Menu active_lang={lang} languages={dbdata.lang} opt_addr="#Home;#Galerie-Foto;#descriere;#tarife;#offers;#Rezerva-acum" ad_cl_to="#Rezerva-acum" />
			</div>
			<div id="top_lang">
				{dbdata.lang.map((item, id) => (
												<div key={id} className="lang_div">
													<Link to={item === "ro" ? "/" : "/"+item}>
														<img id={"lang_img_"+item} className="img_container_dim" src={server+"images/"+item+".png"} alt={"language "+item} />
													</Link>
												</div>
												)
								)
				}
			</div>
		</div>
		<div id="middle_container">
			<div id="middle">
				<div id="Home" className="rows-h">
					<div id="prez_images" className="row_left_div">
					{dbdata.poze_slide.map((item, id) => (
												<img key={id} id={"img_"+id} className={id === 0 ? "img_nr img_round_c img_pres img_container_dim img_pres_active" : "img_nr img_round_c img_pres img_container_dim"} src={slide_img_loc+item} alt={"slide "+item} />
												)
								)}
					</div>
					<div id="div_1_txt" className="white_text_div row_right_div">
						<div id="span_group">
							<span><h1>{dbdata.title_sectiune}</h1></span>
							<span>{dbdata.text_home}</span>
							<br /><br />
						</div>
						<div id="row_1_res_div">
							<div id="row_1_res_cal">
								<input type="text" id="calendar" className="tcal f_input" placeholder={dbdata.cal_txt} name="cal" readOnly />
							</div>
							<div id="row_1_res_btn">
								<button className="res_btn" type="button" onClick={()=>{gotoForm();}}>{dbdata.cal_btn}</button>
							</div>
						</div>
						<div id="span_group_info">
							<br /><br />
							<span>{dbdata.text_home_info}</span>
							<br /><br />
						</div>
						<div id="row_2_visit_div">
							<button className="res_btn" type="button" onClick={()=>{setVisionForm(true);}}>{dbdata.open_visit_btn}</button>
						</div>
					</div>
				</div>
				<div id="Galerie-Foto" className="rows-h">
					<PhotoG photo_list={dbdata.gallery} />
				</div>
				<div id="descriere" className="rows-h">
					<div id="desc" className="row_center_div">
						<span><h1>{dbdata.titlu_desc}</h1></span>
						<br />
						<span>{dbdata.desc}</span>
					</div>
				</div>
				
				{show_prices()}
				
				{show_offers()}
				
				<div id="Rezerva-acum" className="rows-h">
					<div id="form_txt" className="row_left_div">
						<span><h1>{dbdata.contact_title}</h1></span>
						<span>{dbdata.desc_contact}</span>
					</div>
					<div id="form_input" className="row_right_div">
						<FormRez nume_campuri={dbdata.form_names} 
								mesaj_gdpr={dbdata.form_condition} 
								notice={dbdata.form_notice} 
								res_msg_sent={dbdata.res_form_msg_sent} 
								res_msg_err={dbdata.res_form_msg_err} 
								res_msg_no_name={dbdata.res_form_msg_no_name} 
								res_msg_no_period={dbdata.res_form_msg_no_period} 
								res_msg_no_phone={dbdata.res_form_msg_no_phone}
								res_msg_no_email={dbdata.res_form_msg_no_email}
								/>
					</div>
				</div>
				<div id="harta" className="rows-h">
					{show_map()}
					
				</div>
			</div>
		</div>
		<div id="bottom">
		<span><a href="https://www.oferta-creare-website.ro">{dbdata.semnatura}</a></span>
		<Link to={"/Gdpr/"+lang}> {dbdata.link_gdpr}</Link>
		<Outlet />
		</div>
		{set_meta()}
		{window.f_tcalInit()}
    </div>
	);
	}