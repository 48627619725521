import './Gdpr.css';
import Menu from '../comp/Menu.js';
import logo_main from '../images/logo.jpg';
import { useState, useEffect} from "react";
import XmlRequest from '../comp/XmlReq.js';
import { Link, useParams } from "react-router-dom";

export default function Gdpr()
	{
	var server = "https://nomadhomeoffice.eu/";
	
	//gdprdata stores data from server
	var [gdprdata, setData] = useState({lang:[], 
										gdpr:"",
										menu:"",
										semnatura:""
										});
	
	var check_lang = useParams().lang;
	var lang = "";
	if(useParams().lang !== undefined)
		{
		lang = check_lang;
		}

	// get data from server
	useEffect(() => {
					XmlRequest("POST", server+"get_gdpr.php", "language="+lang).then
						(
						function(value) {
										if(value !== "")
											{
											document.getElementById("div_spinner").style.display = "none";
											}
										setData(JSON.parse(value));
										if(lang === "")
											{
											document.documentElement.lang ="ro";
											}
										else
											{
											document.documentElement.lang = lang;
											}
										},
						function(error) {console.log(error);}
						);
					}, [lang]);
	return (
	<div id="div_main">
		<div id="div_spinner">
			<img id="img_spinner" src={server+"images/gear_loader.png"} alt="" />
		</div>
		<div id="top">
			<div id="logo_container">
				<a href={server}>
					<img id="logo_img" className="img_container_dim" src={logo_main} alt="logo" />
				</a>
			</div>
			<div id="top_menu">
				<Menu active_lang={lang} languages={gdprdata.lang} opt_addr={"/"+lang+";/"+lang+"#Galerie-Foto;/"+lang+"#descriere;/"+lang+"#tarife;/"+lang+"#offers;/"+lang+"#Rezerva-acum"} ad_cl_to="#Rezerva-acum" />
			</div>
			<div id="top_lang">
				{gdprdata.lang.map((item, id) => (
												<div key={id} className="lang_div">
													<Link to={item === "ro" ? "/Gdpr/" : "/Gdpr/"+item}>
														<img id={"lang_img_"+item} className="img_container_dim" src={server+"images/"+item+".png"} alt={"language "+item} />
													</Link>
												</div>
												)
								)
				}
			</div>
		</div>
		<div id="middle_container">
			<div id="middle">
				<span id="span_gdpr">{gdprdata.gdpr}</span>
			</div>
		</div>
		<div id="bottom">
		<span><a href="https://www.oferta-creare-website.ro">{gdprdata.semnatura}</a></span><br />
		</div>
    </div>
	);
	}