export default function XmlRequest(type, proc_file, data)
	{
	let myPromise = new Promise(function(value, error)
		{
		var xhr = new XMLHttpRequest();
		xhr.open(type, proc_file, true);
		xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		xhr.send(data);
		xhr.onreadystatechange=function()
			{
			if (xhr.readyState===4)
				{
				if (xhr.status===200 || window.location.href.indexOf("http")===-1)
					{
					value(xhr.responseText);
					}
				else
					{
					error("Error in request");
					}
				}
			};
		});
	return myPromise;
	}